var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:_vm.isDriverSidebar ? 'driver-sidebar' : '',attrs:{"fluid":""}},[(!_vm.isDriverSidebar)?_c('div',[_vm._v("Driver App Settings")]):_vm._e(),(!_vm.isDriverSidebar)?_c('v-checkbox',{attrs:{"id":`users-driver-settings-tab-checkbox-is-driver`,"label":"Is this User a Driver?","disabled":_vm.isUserDriverCheckboxDisabled},on:{"change":_vm.changeDriver},model:{value:(_vm.userDriverCheckbox),callback:function ($$v) {_vm.userDriverCheckbox=$$v},expression:"userDriverCheckbox"}}):_vm._e(),(_vm.userDriverCheckbox)?_c('v-form',{ref:"driverSettings"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{class:!_vm.isDriverSidebar ? 'pr-1' : '',attrs:{"sm8":!_vm.isDriverSidebar}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{class:!_vm.isDriverSidebar ? 'input-margins pr-5' : '',attrs:{"xs12":"","sm5":!_vm.isDriverSidebar,"order-xs1":"","order-sm1":!_vm.isDriverSidebar}},[_c('CRInput',{attrs:{"id":`users-driver-settings-tab-phone-number`,"disabled":_vm.disabled,"mask":'phone',"placeholder":"e.g. (404) 555 - 8888","rules":[
                _vm.isRequired(true, _vm.validatePhone, {
                  req: 'Phone Number Is Required',
                  error: 'Valid Phone Number Is Required',
                }),
              ],"floatinglabel":"Phone Number"},on:{"change":_vm.syncInput},model:{value:(_vm.driverInfoModel.phoneNumber),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "phoneNumber", $$v)},expression:"driverInfoModel.phoneNumber"}})],1),_c('v-flex',{class:!_vm.isDriverSidebar ? 'input-margins' : '',attrs:{"xs12":"","sm2":!_vm.isDriverSidebar,"order-xs3":"","order-sm6":!_vm.isDriverSidebar}},[_c('div',{attrs:{"id":"users-driver-settings-tab-select-license-state-container"}},[_c('CRSelect',{attrs:{"id":`users-driver-settings-tab-select-state`,"label":"License State","placeholder":"Select state","disabled":_vm.disabled,"items":_vm.UsersSchema.states},on:{"change":_vm.syncInput},model:{value:(_vm.driverInfoModel.licenseState),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "licenseState", $$v)},expression:"driverInfoModel.licenseState"}})],1)]),_c('v-flex',{class:!_vm.isDriverSidebar ? 'input-margins pr-5' : '',attrs:{"xs12":"","sm5":!_vm.isDriverSidebar,"order-xs2":"","order-sm5":!_vm.isDriverSidebar}},[_c('CRInput',{attrs:{"id":`users-driver-settings-tab-license-number`,"disabled":_vm.disabled,"rules":[
                _vm.minimumRequirements ||
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'License Number Is Required',
                    error: 'License Number Is Required',
                  }),
              ],"floatinglabel":"License Number"},on:{"change":_vm.syncInput},model:{value:(_vm.driverInfoModel.licenseNumber),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "licenseNumber", $$v)},expression:"driverInfoModel.licenseNumber"}})],1),_c('v-flex',{class:!_vm.isDriverSidebar ? 'input-margins' : '',attrs:{"xs12":"","sm2":!_vm.isDriverSidebar,"order-xs4":"","order-sm7":!_vm.isDriverSidebar}},[_c('div',{attrs:{"id":"users-driver-settings-tab-select-license-expiration-month-container"}},[_c('CRSelect',{attrs:{"id":`users-driver-settings-tab-license-expiration-month`,"disabled":_vm.disabled,"rules":[
                  _vm.minimumRequirements ||
                    _vm.isRequired(!_vm.minimumRequirements, _vm.isNotEmpty, {
                      req: 'Expiration Month Is Required',
                      error: 'Expiration Month Is Required',
                    }),
                ],"label":"Expiration Month","placeholder":"Select expiration month","item-value":"number","item-text":"name","items":_vm.months},on:{"change":_vm.syncInput},model:{value:(_vm.driverInfoModel.licenseExpirationMonth),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "licenseExpirationMonth", $$v)},expression:"driverInfoModel.licenseExpirationMonth"}})],1)]),_c('v-flex',{attrs:{"xs12":"","sm2":!_vm.isDriverSidebar,"order-xs5":"","order-sm8":!_vm.isDriverSidebar}},[_c('div',{attrs:{"id":"users-driver-settings-tab-select-license-expiration-year"}},[_c('CRSelect',{attrs:{"id":`users-driver-settings-tab-select-license-expiration-year`,"disabled":_vm.disabled,"rules":[
                  _vm.minimumRequirements ||
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'Expiration Year Is Required',
                      error: 'Expiration Year Is Required',
                    }),
                ],"label":"Expiration Year","placeholder":"Select expiration year","items":_vm.years},on:{"change":_vm.syncInput},model:{value:(_vm.driverInfoModel.licenseExpirationYear),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "licenseExpirationYear", $$v)},expression:"driverInfoModel.licenseExpirationYear"}})],1)])],1)],1)],1),_c('v-divider'),_c('h6',[_vm._v("Certifications")]),_c('v-layout',{staticClass:"margin-b-5",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-checkbox',{staticClass:"padding-t-0",attrs:{"label":"SPAB","disabled":_vm.disabled,"hide-details":""},model:{value:(_vm.driverInfoModel.spabCertified),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "spabCertified", $$v)},expression:"driverInfoModel.spabCertified"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-checkbox',{staticClass:"padding-t-0",attrs:{"label":"19A","disabled":_vm.disabled,"hide-details":""},model:{value:(_vm.driverInfoModel.nineteenACertified),callback:function ($$v) {_vm.$set(_vm.driverInfoModel, "nineteenACertified", $$v)},expression:"driverInfoModel.nineteenACertified"}})],1)],1),_c('v-divider'),_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('h6',[_vm._v("Vehicle Types")]),_c('v-layout',{staticClass:"amenities",attrs:{"row":""}},_vm._l((_vm.driverInfoModel.driverSupportedVehicles),function(option,optKey){return _c('v-flex',{key:optKey,attrs:{"xs4":""}},[_c('v-checkbox',{staticClass:"amenity-box",attrs:{"id":`users-driver-settings-tab-checkbox-vehicle-type-${optKey}`,"hide-details":"","label":option.label,"disabled":_vm.disabled,"ripple":false},on:{"change":_vm.syncInput},model:{value:(
                _vm.driverInfoModel.driverSupportedVehicles[optKey].supported
              ),callback:function ($$v) {_vm.$set(_vm.driverInfoModel.driverSupportedVehicles[optKey], "supported", $$v)},expression:"\n                driverInfoModel.driverSupportedVehicles[optKey].supported\n              "}})],1)}),1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }