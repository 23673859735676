<template>
  <v-container fluid :class="isDriverSidebar ? 'driver-sidebar' : ''">
    <div v-if="!isDriverSidebar">Driver App Settings</div>
    <v-checkbox
      v-if="!isDriverSidebar"
      :id="`users-driver-settings-tab-checkbox-is-driver`"
      v-model="userDriverCheckbox"
      label="Is this User a Driver?"
      :disabled="isUserDriverCheckboxDisabled"
      @change="changeDriver"
    />
    <v-form v-if="userDriverCheckbox" ref="driverSettings">
      <v-layout row wrap>
        <v-flex :sm8="!isDriverSidebar" :class="!isDriverSidebar ? 'pr-1' : ''">
          <v-layout row wrap>
            <v-flex
              xs12
              :sm5="!isDriverSidebar"
              order-xs1
              :order-sm1="!isDriverSidebar"
              :class="!isDriverSidebar ? 'input-margins pr-5' : ''"
            >
              <CRInput
                :id="`users-driver-settings-tab-phone-number`"
                v-model="driverInfoModel.phoneNumber"
                :disabled="disabled"
                :mask="'phone'"
                placeholder="e.g. (404) 555 - 8888"
                :rules="[
                  isRequired(true, validatePhone, {
                    req: 'Phone Number Is Required',
                    error: 'Valid Phone Number Is Required',
                  }),
                ]"
                floatinglabel="Phone Number"
                @change="syncInput"
              />
            </v-flex>
            <v-flex
              xs12
              :sm2="!isDriverSidebar"
              order-xs3
              :order-sm6="!isDriverSidebar"
              :class="!isDriverSidebar ? 'input-margins' : ''"
            >
              <div
                id="users-driver-settings-tab-select-license-state-container"
              >
                <CRSelect
                  :id="`users-driver-settings-tab-select-state`"
                  v-model="driverInfoModel.licenseState"
                  label="License State"
                  placeholder="Select state"
                  :disabled="disabled"
                  :items="UsersSchema.states"
                  @change="syncInput"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              :sm5="!isDriverSidebar"
              order-xs2
              :order-sm5="!isDriverSidebar"
              :class="!isDriverSidebar ? 'input-margins pr-5' : ''"
            >
              <CRInput
                :id="`users-driver-settings-tab-license-number`"
                v-model="driverInfoModel.licenseNumber"
                :disabled="disabled"
                :rules="[
                  minimumRequirements ||
                    isRequired(true, isNotEmpty, {
                      req: 'License Number Is Required',
                      error: 'License Number Is Required',
                    }),
                ]"
                floatinglabel="License Number"
                @change="syncInput"
              />
            </v-flex>
            <v-flex
              xs12
              :sm2="!isDriverSidebar"
              order-xs4
              :order-sm7="!isDriverSidebar"
              :class="!isDriverSidebar ? 'input-margins' : ''"
            >
              <div
                id="users-driver-settings-tab-select-license-expiration-month-container"
              >
                <CRSelect
                  :id="`users-driver-settings-tab-license-expiration-month`"
                  v-model="driverInfoModel.licenseExpirationMonth"
                  :disabled="disabled"
                  :rules="[
                    minimumRequirements ||
                      isRequired(!minimumRequirements, isNotEmpty, {
                        req: 'Expiration Month Is Required',
                        error: 'Expiration Month Is Required',
                      }),
                  ]"
                  label="Expiration Month"
                  placeholder="Select expiration month"
                  item-value="number"
                  item-text="name"
                  :items="months"
                  @change="syncInput"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              :sm2="!isDriverSidebar"
              order-xs5
              :order-sm8="!isDriverSidebar"
            >
              <div
                id="users-driver-settings-tab-select-license-expiration-year"
              >
                <CRSelect
                  :id="`users-driver-settings-tab-select-license-expiration-year`"
                  v-model="driverInfoModel.licenseExpirationYear"
                  :disabled="disabled"
                  :rules="[
                    minimumRequirements ||
                      isRequired(true, isNotEmpty, {
                        req: 'Expiration Year Is Required',
                        error: 'Expiration Year Is Required',
                      }),
                  ]"
                  label="Expiration Year"
                  placeholder="Select expiration year"
                  :items="years"
                  @change="syncInput"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider />
        <h6>Certifications</h6>
        <v-layout row wrap class="margin-b-5">
          <v-flex xs4>
            <v-checkbox
              v-model="driverInfoModel.spabCertified"
              label="SPAB"
              :disabled="disabled"
              hide-details
              class="padding-t-0"
            />
          </v-flex>
          <v-flex xs4>
            <v-checkbox
              v-model="driverInfoModel.nineteenACertified"
              label="19A"
              :disabled="disabled"
              hide-details
              class="padding-t-0"
            />
          </v-flex>
        </v-layout>
      <v-divider />
      <v-layout row>
        <v-layout column>
          <h6>Vehicle Types</h6>
          <v-layout class="amenities" row>
            <v-flex xs4
              v-for="(option, optKey) in driverInfoModel.driverSupportedVehicles"
              :key="optKey"
            >
              <v-checkbox
                :id="`users-driver-settings-tab-checkbox-vehicle-type-${optKey}`"
                v-model="
                  driverInfoModel.driverSupportedVehicles[optKey].supported
                "
                class="amenity-box"
                hide-details
                :label="option.label"
                :disabled="disabled"
                :ripple="false"
                @change="syncInput"
              />
            </v-flex>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-form>
  </v-container>
</template>
<script>
import UsersSchema from '@/components/UsersSchema'
import { EventBus } from '@/utils/event-bus'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import { mapActions, mapGetters } from 'vuex'
import { isNotEmpty, isRequired, validateEmail, validatePhone } from '@/utils/validators'
import { DateTime } from 'luxon'

const currentYear = new Date().getFullYear()
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

const years = range(currentYear + 20, currentYear - 1, -1)

export default {
  props: {
    mode: {
      type: String,
      default: () => 'view',
    },
    isUserDriver: {
      type: Boolean,
      default: false,
    },
    selectedRoles: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    isDriverSidebar: {
      type: Boolean,
      default: false,
    },
    minimumRequirements: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      UsersSchema,
      years,
      userDriverCheckbox: false,
      driverInfoModel: {
        phoneNumber: '',
        licenseNumber: '',
        licenseState: '',
        licenseExpirationMonth: null,
        licenseExpirationYear: null,
        drugTestNumber: null,
        drugTestExpiration: null,
        notes: '',
        driverNotes: [],
        driverSupportedVehicles: deepClone(UsersSchema.vehicleOptions),
        spabCertified: false,
        nineteenACertified: false,
      },
      drugModel: {
        expirationMonth: null,
        expirationYear: null,
      },
      months: [
        {
          name: 'January',
          short: 'Jan',
          number: 1,
          days: 31,
        },
        {
          name: 'February',
          short: 'Feb',
          number: 2,
          days: 28,
        },
        {
          name: 'March',
          short: 'Mar',
          number: 3,
          days: 31,
        },
        {
          name: 'April',
          short: 'Apr',
          number: 4,
          days: 30,
        },
        {
          name: 'May',
          short: 'May',
          number: 5,
          days: 31,
        },
        {
          name: 'June',
          short: 'Jun',
          number: 6,
          days: 30,
        },
        {
          name: 'July',
          short: 'Jul',
          number: 7,
          days: 31,
        },
        {
          name: 'August',
          short: 'Aug',
          number: 8,
          days: 31,
        },
        {
          name: 'September',
          short: 'Sep',
          number: 9,
          days: 30,
        },
        {
          name: 'October',
          short: 'Oct',
          number: 10,
          days: 31,
        },
        {
          name: 'November',
          short: 'Nov',
          number: 11,
          days: 30,
        },
        {
          name: 'December',
          short: 'Dec',
          number: 12,
          days: 31,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      userChanges: 'users/getUser',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    isModeReferralAdd() {
      return this.mode === 'referralAdd'
    },
    isModeDriverAdd() {
      return this.mode === 'driverAdd'
    },
    disabled() {
      return this.isModeView
    },
    isUserDriverCheckboxDisabled() {
      return (
        this.disabled ||
        this.isModeReferralAdd ||
        this.isModeDriverAdd ||
        this.row.groupId === 4
      )
    },
    previousDriverNotes() {
      return (
        this.driverInfoModel.driverNotes?.[0]?.html ||
        this.driverInfoModel.driverNotes?.[0]?.note ||
        this.driverInfoModel.notes
      )
    },
  },
  watch: {
    userChanges: {
      deep: true,
      handler(newState) {
        if (!this.isDriverSidebar) {
          this.sync(newState)
        }
      },
    },
    isUserDriver: {
      immediate: true,
      handler(value) {
        if (value !== this.userDriverCheckbox) {
          this.userDriverCheckbox = value
        }
      },
    },
    selectedRoles: {
      immediate: true,
      handler(value) {
        if (value.includes('is_driver')) {
          this.userDriverCheckbox = true
        }
      },
    },
    userDriverCheckbox(value) {
      EventBus.$emit('user-driver-checkbox-changed', value)
    },
  },
  mounted() {
    if (this.isModeReferralAdd || this.isModeDriverAdd) {
      this.userDriverCheckbox = true
    }
  },
  methods: {
    ...mapActions({
      setUser: 'users/setUser',
    }),
    isRequired,
    isNotEmpty,
    validateEmail,
    validatePhone,
    sync(state) {
      this.driverInfoModel = deepClone(state)

      if ((this.driverInfoModel.driverSupportedVehicles || []).length === 0) {
        this.driverInfoModel.driverSupportedVehicles = deepClone(
          UsersSchema.vehicleOptions
        )
      }

      this.driverInfoModel.driverSupportedVehicles.forEach((type) => {
        if (!type.label) {
          type.label = UsersSchema.vehicleOptions.find(
            (defaultType) => defaultType.vehicleTypeId === type.vehicleTypeId
          ).label
        }
      })

      const drugDate = this.driverInfoModel?.drugTestExpiration
        ? DateTime.fromSQL(this.driverInfoModel.drugTestExpiration)
        : null

      if (drugDate) {
        this.drugModel.expirationMonth = drugDate
          ? this.months.find((month) => month.number === drugDate.month)?.number
          : null

        this.drugModel.expirationYear = drugDate ? drugDate.year : null
      }
    },
    async syncInput() {
      if (
        this.drugModel.expirationMonth !== null &&
        this.drugModel.expirationYear !== null
      ) {
        const newDrugExpirationDate = DateTime.fromObject({
          day: 1,
          month: this.drugModel.expirationMonth,
          year: this.drugModel.expirationYear,
        })

        this.driverInfoModel.drugTestExpiration = newDrugExpirationDate.toFormat(
          'yyyy-MM-dd'
        )
      }
      await this.$store.dispatch('users/setUser', this.driverInfoModel)
    },
    updateDriverNoteText(value) {
      this.driverInfoModel.notes = value
      if (this.driverInfoModel.driverNotes?.[0]) {
        this.driverInfoModel.driverNotes[0].note = value
      } else {
        this.driverInfoModel.driverNotes = []
        this.driverInfoModel.driverNotes.push({ note: value })
      }
      this.syncInput()
    },
    updateDriverNoteHtml(value) {
      if (this.driverInfoModel.driverNotes?.[0]) {
        this.driverInfoModel.driverNotes[0].html = value
      } else {
        this.driverInfoModel.driverNotes = []
        this.driverInfoModel.driverNotes.push({ html: value })
      }
      this.syncInput()
    },
    changeDriver(isDriver) {
      const updatedUser = { treatAsDriver: isDriver, ...this.userChanges }
      this.setUser(updatedUser)
    },
  },
}
</script>

<style lang="scss" scoped>
.driver-sidebar {
  padding: 0px !important;
}

.form-container {
  background-color: $blue-light;
}

.amenities {
  flex-wrap: wrap;
  .amenity-box {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}

.input-margins {
  margin-right: 4px;
}

.amenities > .checkbox > .v-input__control > .v-input__slot {
  background-color: transparent !important;
}

::v-deep .v-input--checkbox > .v-input__control > .v-input__slot {
  background-color: transparent !important;
}
</style>
